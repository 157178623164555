<template>
    <layout-default>
        <section class="services bg-light py-1" data-description="Anmeldeformular">
            <header-with-line header="Anmeldeformular" class="mb-5"></header-with-line>
            <div class="container">
                <anmeldeformular></anmeldeformular>
            </div>
        </section>
    </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';
import HeaderWithLine from '../components/Ui/HeaderWithLine.vue';
import Anmeldeformular from '../components/Einrichtungen/AnmeldeFormular.vue';

export default {
    name: 'EinrichtungPatientenanmeldung',
    data() {
        return {
            publicPath: process.env.BASE_URL
        };
    },
    components: {
        LayoutDefault,
        HeaderWithLine,
        Anmeldeformular
    }
};
</script>

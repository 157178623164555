<template>
    <div>
        <h1>Anmeldeformular für Bewohner sozialer Einrichtungen</h1>
        <p>Für eine reibungslose Therapieplanung und vollständige Datenübermittlung</p>

        <form @submit.prevent="submitForm" enctype="multipart/form-data">
            <!-- 1. Einrichtung -->
            <h2>1. Einrichtung</h2>
            <div>
                <div class="mb-3">
                    <label for="yourName" class="form-label">Ihr Name und Position</label>
                    <input type="text" class="form-control" id="yourName" v-model="form.yourName" required />
                    <small class="form-text text-muted">
                        Als ausfüllende Person, z.B. Pflegekraft, Betreuungspersonal
                    </small>
                </div>
                <div class="mb-3">
                    <label for="facilityName" class="form-label">Name der Einrichtung</label>
                    <input type="text" class="form-control" id="facilityName" v-model="form.facilityName" required />
                </div>
            </div>

            <!-- 2. Bewohnerdaten -->
            <h2>2. Bewohnerdaten</h2>
            <div>
                <div class="mb-3">
                    <label for="salutation" class="form-label">Anrede</label>
                    <select class="form-select" id="salutation" v-model="form.salutation" required>
                        <option value="">Bitte auswählen</option>
                        <option value="Herr">Herr</option>
                        <option value="Frau">Frau</option>
                    </select>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="firstName" class="form-label">Vorname</label>
                        <input type="text" class="form-control" id="firstName" v-model="form.firstName" required />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="lastName" class="form-label">Nachname</label>
                        <input type="text" class="form-control" id="lastName" v-model="form.lastName" required />
                    </div>
                </div>
                <div class="mb-3">
                    <label for="birthDate" class="form-label">Geburtsdatum</label>
                    <input type="date" class="form-control" id="birthDate" v-model="form.birthDate" required />
                </div>
                <div class="mb-3">
                    <label for="location" class="form-label">Stockwerk und Zimmernummer des Bewohners</label>
                    <input type="text" class="form-control" id="location" v-model="form.location" required />
                </div>
                <div class="mb-3">
                    <label for="residentPhoto" class="form-label">Foto des Bewohners (optional)</label>
                    <input type="file" class="form-control file-input pt-2" id="residentPhoto" @change="handleFileUpload"
                        accept="image/*" capture="environment" />
                    <small class="form-text text-muted">
                        Zur schnellen Identifikation bei Vertretung
                    </small>
                    <!-- Bildvorschau -->
                    <div v-if="residentPhotoUrl" class="mt-3">
                        <img :src="residentPhotoUrl" alt="Foto des Bewohners" style="max-width: 200px; max-height: 200px;">
                    </div>
                </div>
            </div>

            <!-- 3. Verordnung -->
            <h2>3. Verordnung</h2>
            <div class="mb-3">
                <label for="prescription" class="form-label">Verordnung als Bild / PDF hochladen</label>
                <input type="file" class="form-control file-input pt-2" id="prescription" @change="handlePrescriptionUpload"
                    accept="image/*,application/pdf" capture="environment" />
                <!-- Vorschau oder Dateiname -->
                <div v-if="prescriptionPreview" class="mt-3">
                    <div v-if="isPrescriptionImage">
                        <img :src="prescriptionPreview" alt="Vorschau der Verordnung"
                            style="max-width: 200px; max-height: 200px;">
                    </div>
                    <div v-else>
                        <p>Datei hochgeladen: {{ form.prescription.name }}</p>
                    </div>
                </div>
            </div>

            <!-- 4. Kontaktinformation und Betreuung -->
            <h2>4. Kontaktinformation und Betreuung</h2>
            <div>
                <div class="mb-3">
                    <label for="contactName" class="form-label">Name Angehöriger / Betreuung</label>
                    <input type="text" class="form-control" id="contactName" v-model="form.contactName" required />
                </div>
                <div class="mb-3">
                    <label for="contactEmail" class="form-label">
                        E-Mail-Adresse Angehöriger / Betreuung
                    </label>
                    <input type="email" class="form-control" id="contactEmail" v-model="form.contactEmail" required />
                    <small class="form-text text-muted">
                        Zur Anforderung von Zuzahlungen und für Abrechnungsinformationen
                    </small>
                </div>
                <div class="alert alert-info" role="alert">
                    Hinweis: Diese Kontaktinformation ist Voraussetzung für die Therapieplanung. Ohne gültige
                    E-Mail-Adresse zur Abrechnung und Kommunikation kann keine Therapie gestartet werden.
                </div>
            </div>

            <!-- 5. Aufenthalts- und Abrechnungsinformationen -->
            <h2>5. Aufenthalts- und Abrechnungsinformationen</h2>
            <div class="mb-3">
                <label for="residencyStatus" class="form-label">Aufenthaltsstatus in der Einrichtung</label>
                <select class="form-select" id="residencyStatus" v-model="form.residencyStatus" required>
                    <option value="">Bitte auswählen</option>
                    <option value="Kurzzeitpflege">Kurzzeitpflege</option>
                    <option value="Dauerhafter Aufenthalt">Dauerhafter Aufenthalt</option>
                </select>
            </div>

            <div v-if="form.residencyStatus === 'Kurzzeitpflege'" class="mb-3">
                <label class="form-label">Bitte Zeitraum von/bis angeben</label>
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label for="shortTermStartDate" class="form-label">Zeitraum von</label>
                        <input type="date" class="form-control" id="shortTermStartDate"
                            v-model="form.shortTermStartDate" required />
                    </div>
                    <div class="col-md-6 mb-3">
                        <label for="shortTermEndDate" class="form-label">Zeitraum bis</label>
                        <input type="date" class="form-control" id="shortTermEndDate" v-model="form.shortTermEndDate" required />
                    </div>
                </div>
            </div>

            <div v-if="form.residencyStatus === 'Dauerhafter Aufenthalt'" class="mb-3">
                <label for="permanentStartDate" class="form-label">Ab / seit wann</label>
                <input type="date" class="form-control" id="permanentStartDate" v-model="form.permanentStartDate" required />
            </div>

            <div class="mb-3">
                <label for="coPaymentExemption" class="form-label">Zuzahlungsbefreiung</label>
                <select class="form-select" id="coPaymentExemption" v-model="form.coPaymentExemption">
                    <option value="">Bitte auswählen</option>
                    <option value="Ja">Ja</option>
                    <option value="Nein">Nein</option>
                </select>
                <small class="form-text text-muted">(Information durch Pflegekraft genügt)</small>
            </div>

            <!-- 6. Einverständnis und Datenschutz -->
            <h2>6. Einverständnis und Datenschutz</h2>
            <div class="mb-3">
                <p>Datenschutzformular herunterladen und unterschreiben lassen</p>
                <a href="/Einwilligungserklärung.pdf" target="_blank" class="btn btn-secondary">Datenschutzformular</a>
                <small class="form-text text-muted">
                    Zur rechtskonformen Einwilligung in die Datenverarbeitung des Bewohners. Bitte je nach
                    Fall durch Pflegepersonal oder Betreuende/Angehörige unterschreiben lassen.
                </small>
            </div>

            <div class="mb-3">
                <label for="privacyAgreement" class="form-label">Unterschriebene Datenschutzerklärung hochladen (optional)</label>
                <input type="file" class="form-control file-input pt-2" id="privacyAgreement"
                    @change="handlePrivacyAgreementUpload" accept="image/*,application/pdf" capture="environment" />
                <small class="form-text text-muted">
                    Bitte laden Sie die unterschriebene Datenschutzerklärung hoch.
                </small>
                <!-- Vorschau oder Dateiname -->
                <div v-if="privacyAgreementPreview" class="mt-3">
                    <div v-if="isPrivacyAgreementImage">
                        <img :src="privacyAgreementPreview" alt="Vorschau der Datenschutzerklärung"
                            style="max-width: 200px; max-height: 200px;">
                    </div>
                    <div v-else>
                        <p>Datei hochgeladen: {{ form.privacyAgreement.name }}</p>
                    </div>
                </div>
            </div>

            <div class="alert alert-info" role="alert">
                Hinweis: Eine vollständige und genaue Anmeldung hilft uns, die Therapie bedarfsgerecht und
                effizient zu planen.
            </div>

            <!-- Absenden-Button mit Lade-Spinner -->
            <button type="submit" class="btn btn-primary" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-else>
                    Formular absenden
                </span>
            </button>

            <!-- Erfolg- und Fehlermeldungen -->
            <div class="mt-3">
                <div class="alert alert-success" v-if="successMessage">
                    {{ successMessage }}
                </div>
                <div class="alert alert-danger" v-if="errorMessage">
                    {{ errorMessage }}
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import axios from '../../services/axiosConfig';

export default {
    name: 'Anmeldeformular',
    data() {
        return {
            form: {
                yourName: '',
                facilityName: '',
                salutation: '',
                firstName: '',
                lastName: '',
                birthDate: '',
                location: '',
                residentPhoto: null,
                prescription: null,
                contactName: '',
                contactEmail: '',
                residencyStatus: '',
                shortTermStartDate: '',
                shortTermEndDate: '',
                permanentStartDate: '',
                coPaymentExemption: '',
                privacyAgreement: null
            },
            residentPhotoUrl: null,
            prescriptionPreview: null,
            isPrescriptionImage: false,
            privacyAgreementPreview: null,
            isPrivacyAgreementImage: false,
            loading: false,
            successMessage: '',
            errorMessage: ''
        };
    },
    methods: {
        handleFileUpload(event) {
            const [file] = event.target.files;
            this.form.residentPhoto = file;
            if (file) {
                this.residentPhotoUrl = URL.createObjectURL(file);
            } else {
                this.residentPhotoUrl = null;
            }
        },
        handlePrescriptionUpload(event) {
            const [file] = event.target.files;
            this.form.prescription = file;
            if (file) {
                const fileType = file.type;
                if (fileType.startsWith('image/')) {
                    this.isPrescriptionImage = true;
                    this.prescriptionPreview = URL.createObjectURL(file);
                } else {
                    this.isPrescriptionImage = false;
                    this.prescriptionPreview = null;
                }
            } else {
                this.prescriptionPreview = null;
                this.isPrescriptionImage = false;
            }
        },
        handlePrivacyAgreementUpload(event) {
            const [file] = event.target.files;
            this.form.privacyAgreement = file;
            if (file) {
                const fileType = file.type;
                if (fileType.startsWith('image/')) {
                    this.isPrivacyAgreementImage = true;
                    this.privacyAgreementPreview = URL.createObjectURL(file);
                } else {
                    this.isPrivacyAgreementImage = false;
                    this.privacyAgreementPreview = null;
                }
            } else {
                this.privacyAgreementPreview = null;
                this.isPrivacyAgreementImage = false;
            }
        },
        async submitForm() {
            // Validierung der Eingaben
            if (this.validateForm()) {
                this.loading = true;
                this.successMessage = '';
                this.errorMessage = '';

                const formData = new FormData();

                // Textfelder hinzufügen
                formData.append('yourName', this.form.yourName);
                formData.append('facilityName', this.form.facilityName);
                formData.append('salutation', this.form.salutation);
                formData.append('firstName', this.form.firstName);
                formData.append('lastName', this.form.lastName);

                // Geburtsdatum formatieren
                formData.append('birthDate', this.formatDate(this.form.birthDate));

                formData.append('location', this.form.location);
                formData.append('contactName', this.form.contactName);
                formData.append('contactEmail', this.form.contactEmail);
                formData.append('residencyStatus', this.form.residencyStatus);
                formData.append('coPaymentExemption', this.form.coPaymentExemption);

                // Aufenthaltsdaten abhängig vom Status
                if (this.form.residencyStatus === 'Kurzzeitpflege') {
                    formData.append('shortTermStartDate', this.formatDate(this.form.shortTermStartDate));
                    formData.append('shortTermEndDate', this.formatDate(this.form.shortTermEndDate));
                } else if (this.form.residencyStatus === 'Dauerhafter Aufenthalt') {
                    formData.append('permanentStartDate', this.formatDate(this.form.permanentStartDate));
                }

                // Dateien hinzufügen
                if (this.form.residentPhoto) {
                    formData.append('residentPhoto', this.form.residentPhoto);
                }
                if (this.form.prescription) {
                    formData.append('prescription', this.form.prescription);
                }
                if (this.form.privacyAgreement) {
                    formData.append('privacyAgreement', this.form.privacyAgreement);
                }

                await axios.post('anmeldung/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((response) => {
                        // Erfolgshandhabung
                        if (response.data.status !== 1) {
                            this.errorMessage = response.data.message || 'Es gab ein Problem beim Senden des Formulars.';
                            return;
                        }
                        this.successMessage = 'Ihre Anmeldung wurde erfolgreich übermittelt. Vielen Dank! Der Patient wurde übermittelt.';
                        // Formular zurücksetzen
                        this.resetForm();
                    })
                    .catch(() => {
                        this.errorMessage = 'Es gab ein Problem beim Senden des Formulars. Bitte versuchen Sie es erneut.';
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                this.errorMessage = 'Bitte füllen Sie alle Pflichtfelder aus.';
            }
        },
        validateForm() {
            // Einfache Validierung (kann erweitert werden)
            if (!this.form.facilityName || !this.form.lastName || !this.form.location || !this.form.contactEmail) {
                return false;
            }

            if (this.form.residencyStatus === 'Kurzzeitpflege') {
                if (!this.form.shortTermStartDate || !this.form.shortTermEndDate) {
                    return false;
                }
            } else if (this.form.residencyStatus === 'Dauerhafter Aufenthalt') {
                if (!this.form.permanentStartDate) {
                    return false;
                }
            }

            return true;
        },
        resetForm() {
            // Formular zurücksetzen
            this.form = {
                yourName: '',
                facilityName: '',
                salutation: '',
                firstName: '',
                lastName: '',
                birthDate: '',
                location: '',
                residentPhoto: null,
                prescription: null,
                contactName: '',
                contactEmail: '',
                residencyStatus: '',
                shortTermStartDate: '',
                shortTermEndDate: '',
                permanentStartDate: '',
                coPaymentExemption: '',
                privacyAgreement: null
            };
            this.residentPhotoUrl = null;
            this.prescriptionPreview = null;
            this.isPrescriptionImage = false;
            this.privacyAgreementPreview = null;
            this.isPrivacyAgreementImage = false;
            this.loading = false;
            // Die Erfolgsmeldung bleibt erhalten
            // this.successMessage = '';
            this.errorMessage = '';
        },
        formatDate(date) {
            if (!date) return '';
            // Datum in YYYY-MM-DD formatieren
            const d = new Date(date);
            const year = d.getFullYear();
            const month = `${d.getMonth() + 1}`.padStart(2, '0');
            const day = `${d.getDate()}`.padStart(2, '0');

            return `${year}-${month}-${day}`;
        }
    }
};
</script>

<style scoped>
.anmeldeformular {
    margin-top: 20px;
}

h2 {
    margin-top: 30px;
}

.alert {
    margin-top: 20px;
}

button {
    margin-top: 20px;
}

/* Styling für Datei-Upload-Felder */
.file-input {
    display: flex;
    align-items: center;
}

.file-input::-webkit-file-upload-button {
    padding: 0.5em;
    border: none;
    background: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 0.2em;
}

.file-input::-webkit-file-upload-button:hover {
    background: #0056b3;
}
</style>
